import store from "@/store";
import axios from "axios";
import { toCamel, toSnake } from "snake-camel";

// const url = 'http://localhost:3001/api/';
const url = 'https://api.alloopharma.co/api/';
export const baseURL = url + 'v1';
export const baseURLV2 = url + 'v2';

const api = axios.create({
  baseURL
});

const apiV2 = axios.create({
  baseURL: baseURLV2
});

const updatePharmacy = async (params: object) => {
  api.defaults.headers = store.getters.headers;
  
  return api.put("/pharmacists", toSnake({ pharmacyAttributes: params })).then(response => {
    const data = toCamel(response.data);
    store.dispatch("setUser", data);
    return response.data;
  });
}

const bookShifts = async (shiftIds: object) => {
  api.defaults.headers = store.getters.headers;
  return api
    .post("/shifts/book", toSnake({ shiftIds: shiftIds }))
    .then(response => {
      const shifts = response.data.shifts.map((el: any) => toCamel(el));
      store.dispatch("setSlotsNextConfirmed", shifts);
      return response.data;
    });
};

const getGrades = async () =>
  api.get("/grades").then(response => {
    store.dispatch(
      "setGrades",
      response.data?.map((el: any) => toCamel(el)) || []
    );
    return response.data;
  });

const getShifts = async () =>
  api.get("/shifts").then(response => {
    store.dispatch(
      "setSlotsNextConfirmed",
      response.data?.shifts?.map((el: any) => toCamel(el)) || []
    );
    return response.data;
  });

/* Params for update profile is a hash that can contain those entries
{
    first_name: string,
    last_name: string,
    address: string,
    device_token: string,
    birthday: string ('2021-06-01'),
    gender: string,
    has_check_cgu: boolean,
    has_check_confidentiality: boolean,
    has_check_corporate_behavior: boolean,
    grade_id: number
    transport_available: array of string (car | transport)
    current_activity: string (cdd | cdi | interim | nothing)
    year_graduate: integer
    school: string
}
 */
const updateProfile = async (params: object) => {
  const finalParams = toSnake(params);
  api.defaults.headers = store.getters.headers;
  return api.put("/users/update", finalParams).then(response => {
    const user = toCamel(response.data);
    store.dispatch("setUser", user);
    return response.data;
  });
};

const updateProfileWithFormData = async (params: FormData) => {
  api.defaults.headers = store.getters.headers;
  return api.put("/users/update", params).then(response => {
    const user = toCamel(response.data);
    store.dispatch("setUser", user);
    return user;
  });
};

const updateIBANFormData = async (params: FormData) => {
  api.defaults.headers = store.getters.headers;
  return api.put("/users/update", params).then(response => {
    const user = toCamel(response.data);
    location.reload()
    return response.data;
  });
};

const createMission = async (params: object) => {
  const finalParams = toSnake(params);
  api.defaults.headers = store.getters.headers;
  return api.post("/missions", finalParams).then(response => {
    return response.data;
  });
}

const updateMission = async (id: number, params: object) => {
  const finalParams = toSnake(params);
  api.defaults.headers = store.getters.headers;
  return api.put("/missions/" + id, finalParams).then(response => {
    return toCamel(response.data);
  });
};

const updateUnavailabilities = async (days: object) => {
  api.defaults.headers = store.getters.headers;
  return api
    .put("/shifts/update_unavailabilities", toSnake({ unavailableDays: days }))
    .then(response => {
      store.dispatch("setUnavailabilities", response.data);
      return response.data;
    });
};

const validateShift = async (id: number) => {
  api.defaults.headers = store.getters.headers;
  return api
    .put("/shifts/"+id+"/validate_shift")
    .then(response => {
      return response
    });
};

const declineShift = async (id: number) => {
  api.defaults.headers = store.getters.headers;
  return api
    .put("/shifts/"+id+"/decline_shift")
    .then(response => {
      return response
    });
};

// la response vaux true si le code est valide
const verifyCode = async (code: number) => {
  api.defaults.headers = store.getters.headers;

  return api
    .get(`/users/verify?pin=${code}`)
    .then(() => {
      store.dispatch("setConfirmPhone", true);
      return true;
    })
    .catch(() => {
      return false;
    });
};

// la response vaux true si les headers sont valide
const verifyHeaders = async () => {
  api.defaults.headers = store.getters.headers;

  return api
    .get("/auth/validate_token")
    .then(() => true)
    .catch(() => false);
};

// tu peux réutiliser les missions mais le call store est fait de sorte a ce que t'ai uniquement a call la methode
const getMissions = async (offset: number) => {
  apiV2.defaults.headers = store.getters.headers;
  const departments = store.getters.departmentsSelected;
  const activeDepartments = departments.map((department: string) => department.split("(")[0].replace(/^\s+|\s+$/g, ''));
  const distinctSignsSensibility = store.getters.distinctSignsSensibilitySelected;
  
  return apiV2.get(`/missions?offset=${offset}&departments=[${activeDepartments.join(',')}]&distinct_signs_sensibility=${distinctSignsSensibility}`)
    .then(response => {
      const formatedData = response.data.missions.map((el: any) => toCamel(el));
      store.dispatch('setDepartments', response.data.postal_code_count);const difference = response.data.postal_code_count.filter( (x: any) => departments.includes(x));
      store.dispatch("setPreferencesFilter", { departments: difference })
      if (offset === 0) {
        store.dispatch('setMissionsPotential', formatedData);
      } else {
        store.dispatch("addMissionsPotential", formatedData);
      }
      return formatedData;
    });
};

const getMissionInfo = async (id: number) => {
  apiV2.defaults.headers = store.getters.headers;

  return apiV2.get(`/missions/${id}/info`).then(response => toCamel(response.data));
};

// on remplis les store avec l'objet user et l'objet nextSlots
const getDashboard = async () => {
  api.defaults.headers = store.getters.headers;

  return api.get("/users").then(response => {
    store.dispatch("initApp", toCamel(response.data));
    return response.data;
  });
};

const getPharmacistMissions = async () => {
  apiV2.defaults.headers = store.getters.headers;
  
  return apiV2.get("/pharmacists/missions").then(response => {
    // @TODO store in the store
    return response.data;
  });
};

const signUp = async (
  email: string,
  password: string,
  phone: string,
  sponsorCode: string
) => {
  return api
    .post(
      "/auth",
      toSnake({ email, password, phone, sponsoredCode: sponsorCode })
    )
    .then(response => {
      const { headers } = response;
      const { client, uid } = headers;
      const apiHeaders = {
        "access-token": headers["access-token"],
        client,
        uid
      };
      store.dispatch("setHeaders", apiHeaders);
      store.dispatch("setUser", toCamel(response.data.data));
      return response.data;
    });
};

const signIn = async (email: string, password: string) => {
  return api.post("/auth/sign_in", { email, password }).then(response => {
    const { headers } = response;
    const { client, uid } = headers;
    const apiHeaders = { "access-token": headers["access-token"], client, uid };
    store.dispatch("setHeaders", apiHeaders);
    return response.data;
  });
};

const signOut = async () => {
  api.defaults.headers = store.getters.headers;
  return api.delete("/auth/sign_out").then(() => {
    store.dispatch("resetStorage", {});
    return true;
  });
};

const deleteAccount = async () => {
  api.defaults.headers = store.getters.headers;
  return api.delete("/users").then(() => {
    store.dispatch("resetStorage", {});
    return true;
  });
};

const askSms = async () => {
  api.defaults.headers = store.getters.headers;
  return api.get("/users/ask_sms").then(() => {
    return true;
  });
};

const askResetPassword = async (phone: string) => {
  return api
    .post("/users/ask_reset_password", { phone })
    .then(() => true)
    .catch(() => false);
};

const resetPassword = async (code: string, newPassword: string) => {
  return api
    .post("/users/reset_password", { code, password: newPassword })
    .then(response => response.data);
};

const getSoftwares = async () => {
  return api.get("/constants/softwares").then(response => {
    store.dispatch("setSoftwares", response.data);
    return response.data;
  });
};

const autoCompleteAddress = async (query: string) =>
  api.get(`/auto_completion/address?q=${encodeURI(query)}`);

export {
  autoCompleteAddress,
  bookShifts,
  getDashboard,
  getMissions,
  getMissionInfo,
  getGrades,
  getShifts,
  getSoftwares,
  updateUnavailabilities,
  updateProfile,
  updateProfileWithFormData,
  updateIBANFormData,
  validateShift,
  declineShift,
  signUp,
  signIn,
  signOut,
  deleteAccount,
  verifyCode,
  verifyHeaders,
  askSms,
  askResetPassword,
  resetPassword,
  updatePharmacy,
  createMission,
  updateMission,
  
  // Pharmacists specific routes
  getPharmacistMissions
};
