import "es6-promise/auto";
import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import {baseURL} from "@/services/api";

const vuexPersist = new VuexPersist({
  key: "store",
  storage: window.localStorage
});

const settingApp = {
  isDesktop: false,
  isAnimating: false,
}

const SPONSORSHIP_GAIN = 100;
const ALLOOPHARMA_PHONE_INTERIM = "+33619629575";
const ALLOOPHARMA_PHONE_PHARMACIST = "+33186652961";

const headers = {
  accessToken: null,
  client: null,
  uid: null,
};

const user =  {
  isCalendlyMeetTaken: null,
  role: 'user',
  acceptationStatus: 'En attente de complétion du profil',
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  status: null,
  coeff: null,
  createdAt: null,
  id: null,
  isConfirmed: null,
  isApproved: null,
  address: null,
  longitude: null,
  latitude: null,
  iban: null,
  cvLink: null,
  totalGain: null,
  waitingGain: null,
  sponsorshipLink: null,
  unavailabilities: [],
  profilePicture: null,
  pharmacy: {
    id: null,
    name: null,
    address: null,
    logiciel: null,
    transport: null,
    parking: null,
    typology: null,
    hasRobots: null,
    hasRestaurantNear: null,
    hasTrods: null,
    hasTeleconsultationBooth: null,
    hasRestRoom: null,
    hasSepa: false,
    sepaUrl: null,
    isContacted: false,
    missionShifts: [
      /*  Template mission
      {
      id: null,
      day: null,
      begin_time: null,
      end_time: null,
      worked_hours: null,
      status: null,
      created_at: null,
      pause_hours: null,
      is_flexible: null,
      owner_status: null,
      increase: null,
      grade: null,
      pricing: null,
      opinion: null,
      distinctSignsSensibility: null,
      }
      */
    ],
    missingOpinionUsers: [
      /* Template Missing Opinion
      {
        missionShift: {
          id: null,
          day: null,
        },
        user: {
          id: null,
          firstName: null,
          profilePicture: { url: null}
        }
      }
       */
    ],
  }
};

const missionsPotential = [
  {
    salary: 0,
    distance: 0,
    title: "",
    description: "",
    displayAddress: "",
    id: 0,
    pricing: "",
    software: "",
    uniqueUser: false,
    vipType: "", // todo
    dateStart: "2020-12-02",
    dateEnd: "2020-12-04",
    isPremium: false,
    distinctSignsSensibility: "nothing",
    slotsMonths: [
      {
        dateYearMonth: "2021/06",
        days: [
          {
            dayNum: "03",
            dayName: "Jeudi",
            slotsDay: [
              {
                id: 0,
                start: "07:30",
                end: "12:45",
                hourWorked: 0,
                hourRest: 0,
                increase: 0,
                isClicked: false,
                isMatchUnavailabilities: false
              }
            ]
          }
        ]
      },
      {
        dateYearMonth: "2022/06",
        days: [
          {
            dayNum: "03",
            dayName: "Jeudi",
            slotsDay: [
              {
                id: 0,
                start: "07:30",
                end: "12:45",
                hourWorked: 0,
                hourRest: 0,
                isClicked: false,
                isMatchUnavailabilities: false
              }
            ]
          }
        ]
      }
    ]
  }
];

const grades: any = [];
const softwares: any = [];
const departments: any = [];

const slotsNextConfirmed = [
  {
    id: 0,
    missionId: 0,
    status: "Recherche en cours...",
    salary: 0,
    description: "",
    address: "",
    distance: 0.0,
    longitude: 0,
    latitude: 0,
    software: "",
    title: "",
    phone: "",
    vipType: "",
    dateStart: "2020-12-04T10:40",
    dateEnd: "2020-12-04T18:48",
    pharmacyName: "",
    pricing: "",
  },
];

export const POSSIBLE_DISTINCT_SIGNS_SENSIBILITIES =  {
  'everything': '🦄',
  'turban': '🧢',
  'nothing': '🥼',
}

export const POSSIBLE_DISTINCT_SIGNS_ROTATION  = {
  everything: 'turban',
  turban: 'nothing',
  nothing: 'everything',
}

const filter = {
  softwares: [],
  departments: [],
  distinctSignsSensibility: 'nothing',
}

const currentMission = {
  id: null,
}


export default createStore({
  state: {
    headers,
    lastTimeUpdated: 12123,
    user,
    missionsPotential,
    slotsNextConfirmed,
    grades,
    settingApp,
    softwares,
    departments,
    filter,
    currentMission,
  },

  getters: {
    headers: state => state.headers,
    missionsPotential: state => state.missionsPotential,
    slotsNextConfirmed: state => state.slotsNextConfirmed,
    lastSlotNextConfirmed: state => state.slotsNextConfirmed[0],
    unavailabilities: state => state.user.unavailabilities,
    emailUser: state => state.user.email,
    phoneAlloopharma: state => state.user?.role === 'pharmacist' ? ALLOOPHARMA_PHONE_PHARMACIST : ALLOOPHARMA_PHONE_INTERIM,
    phoneNumber: state => state.user.phone,
    address: state => state.user.address,
    totalGain: state => state.user.totalGain,
    waitingGain: state => state.user.waitingGain,
    user: state => state.user,
    firstName: state => state.user.firstName,
    lastName: state => state.user.lastName,
    status: state => state.user.status,
    iban: state => state.user.iban,
    cvLink: state => state.user.cvLink,
    role: state => state.user.role,
    isPharmacist: state => state.user.role === 'pharmacist',
    isProfilFull: state => state.user.acceptationStatus !== 'En attente de complétion du profil',
    isProfilAccepted: state => state.user.acceptationStatus != "Accepté",
    lastTimeUpdated: state => state.lastTimeUpdated,
    sponsorshipLink: state => state.user.sponsorshipLink,
    sponsorshipGain: _ => SPONSORSHIP_GAIN,
    isPhoneConfirmed: state => state.user.isConfirmed,
    isCalendlyMeetTaken: state => state.user.isCalendlyMeetTaken,
    acceptationStatus: state => state.user.acceptationStatus,
    grades: state => state.grades,
    isDesktop: state => state.settingApp.isDesktop,
    isAnimating: state => state.settingApp.isAnimating,
    lngLat: state => ({ lng: state.user.longitude, lat: state.user.latitude }),
    userMarker: state => ({ title: 'Ma position', position: {lng: state.user.longitude, lat: state.user.latitude }, url: baseURL + '/constants/user'}),
    softwares: state => state.softwares,
    departments: state => state.departments,
    softwaresSelected: state => state.filter.softwares,
    departmentsSelected: state => state.filter.departments,
    distinctSignsSensibilitySelected: state => state.filter.distinctSignsSensibility,
    currentMission: state => state.currentMission,
    pharmacy: state => state.user?.pharmacy,
    isPharmacyComplete: state => {
      if (typeof state.user.pharmacy === 'object') {
        return !Object.values(state.user.pharmacy).includes(null);
      }
      return false;
    },
    isPharmacyContacted: state => state.user?.pharmacy?.isContacted,
    isPharmacySignedSepa: state => state.user?.pharmacy?.hasSepa,
    sepaUrl: state => state.user?.pharmacy?.sepaUrl,
    profilePicture: state => state.user?.profilePicture,
    missionShifts: state => state.user?.pharmacy?.missionShifts,
  },
  mutations: {
    RESET_STORAGE(state, _) {
      state.headers = headers;
      state.user = user;
      state.missionsPotential = missionsPotential;
      state.slotsNextConfirmed = slotsNextConfirmed;
      state.lastTimeUpdated = 12123;
      state.currentMission = {id: null};
    },
    SET_HEADERS(state, headers) {
      state.headers = headers;
    },
    SET_USER(state, user) {
      state.user = { ...state.user, ...user };
    },
    ADD_MISSIONS_POTENTIAL(state, missions) {
      missions.forEach((mission: any) => {
        state.missionsPotential.push(mission)
      })
    },
    SET_MISSIONS_POTENTIAL(state, missions) {
      state.missionsPotential = missions;
    },
    RESET_MISSION_POTENTIAL(state, _) {
      state.missionsPotential = missionsPotential;
    },
    SET_SLOTS_NEXT_CONFIRMED(state, slots) {
      state.slotsNextConfirmed = slots;
    },
    SET_UNAVAILABILITIES(state, unavailabilities) {
      state.user.unavailabilities = unavailabilities;
    },
    SET_EMAIL_USER(state, email) {
      state.user.email = email;
    },
    SET_PHONE_NUMBER(state, phone) {
      state.user.phone = phone;
    },
    SET_GRADES(state, grades) {
      state.grades = grades
    },
    INIT_APP(state, payload) {
      state.user = payload;
      state.lastTimeUpdated = Date.now();
    },
    SET_ISDESKTOP(state, payload) {
      state.settingApp.isDesktop = payload;
    },
    SET_IS_ANIMATING(state, payload) {
      console.log(payload)
      state.settingApp.isAnimating = payload;
    },
    SET_CONFIRM_PHONE(state, payload) {
      state.user.isConfirmed = payload;
    },
    SET_SOFTWARES(state, payload) {
      state.softwares = payload;
    },
    SET_PREFERENCES_FILTER(state, payload) {
      state.filter = { ...state.filter, ...payload};
    },
    SET_CURRENT_MISSION(state, payload) {
      state.currentMission = payload;
    },
    SET_DEPARTMENTS(state, payload) {
      state.departments = payload;
    },
    SET_PHARMACY(state, payload) {
      state.user.pharmacy = { ...state.user.pharmacy, ...payload };
    },
    SET_MISSING_OPINION_USERS(state, payload) {
      state.user.pharmacy.missingOpinionUsers = payload;
    }
  },
  actions: {
    ///// Get app from DB ///////////
    initApp({ commit }, payload) {
      commit("INIT_APP", payload);
    },

    ///// set store from DB ////
    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    addMissionsPotential({ commit }, payload) {
      commit("ADD_MISSIONS_POTENTIAL", payload);
    },
    setMissionsPotential({ commit }, payload) {
      commit("SET_MISSIONS_POTENTIAL", payload);
    },
    resetMissionPotential({ commit }, payload) {
      commit('RESET_MISSION_POTENTIAL', payload);
    },
    setSlotsNextConfirmed({ commit }, payload) {
      commit("SET_SLOTS_NEXT_CONFIRMED", payload);
    },

    //// update from app //////
    setHeaders({ commit }, payload) {
      commit("SET_HEADERS", payload);
    },
    resetStorage({ commit }, payload) {
      commit("RESET_STORAGE", payload);
    },
    setUnavailabilities({ commit }, payload) {
      commit("SET_UNAVAILABILITIES", payload);
    },
    setEmailUser({ commit }, payload) {
      commit("SET_EMAIL_USER", payload);
    },
    setPhoneNumber({ commit }, payload) {
      commit("SET_PHONE_NUMBER", payload);
    },
    setGrades({ commit }, payload) {
      commit("SET_GRADES", payload);
    },
    setIsDesktop({ commit }, payload) {
      commit("SET_ISDESKTOP", payload);
    },
    setIsAnimating({ commit }, payload) {
      commit("SET_IS_ANIMATING", payload);
    },
    setConfirmPhone({ commit }, payload) {
      commit("SET_CONFIRM_PHONE", payload);
    },
    setSoftwares({ commit }, payload) {
      commit("SET_SOFTWARES", payload);
    },
    setPreferencesFilter({ commit }, payload) {
      commit("SET_PREFERENCES_FILTER", payload);
    },
    setCurrentMission({commit}, payload) {
      commit("SET_CURRENT_MISSION", payload);
    },
    setDepartments({ commit }, payload) {
      commit("SET_DEPARTMENTS", payload);
    },
    setPharmacy({ commit }, payload) {
      commit("SET_PHARMACY", payload);
    },
    setMissingOpinionUsers({ commit }, payload) {
      commit("SET_MISSING_OPINION_USERS", payload);
    }
  },
  plugins: [vuexPersist.plugin]
});
