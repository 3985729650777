<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar :slot="slotPlace" :class="classSupport">
        <div v-if="isDesktop" class="titleLogo">
          <img class="ion-margin logoTopDesktop" src="../../public/assets/icon/alloopharma-logo.png" >
        </div>

        <ion-tab-button class="text-white" tab="tableau-bord" href="/tableau-bord">
          <img class="icon" src="../../public/assets/icon/house.svg">
          <ion-label>Accueil</ion-label>
        </ion-tab-button>

        <!-- Pharmacists tabs -->
        <ion-tab-button class="text-white" v-if="isPharmacist" @click="iconClicked('missions')" tab="missions" href="/creation-mission">
          <img class="icon" src="../../public/assets/icon/rocket-color.svg">
          <ion-label>Commander</ion-label>
        </ion-tab-button>

        <ion-tab-button class="text-white" v-if="isPharmacist" @click="iconClicked('planning')" tab="planning" href="/mes-missions">
          <img class="icon" src="../../public/assets/icon/calendar-color.svg">
          <ion-label>Commandes</ion-label>
        </ion-tab-button>

        <!--/Pharmacists tabs -->


        <!-- Users tabs -->
        <ion-tab-button class="text-white" v-if="!isPharmacist" @click="iconClicked('missions')" tab="missions" href="/missions">
          <img class="icon" src="../../public/assets/icon/rocket-color.svg">
          <ion-label>Missions</ion-label>
        </ion-tab-button>

        <ion-tab-button class="text-white" v-if="!isPharmacist" @click="iconClicked('planning')" tab="planning" href="/planning">
          <img class="icon" src="../../public/assets/icon/calendar-color.svg">
          <ion-label>Planning</ion-label>
        </ion-tab-button>

        <ion-tab-button class="text-white" v-if="!isPharmacist" @click="iconClicked('availabilities')" tab="disponibilites" href="/disponibilites">
          <img class="icon" src="../../public/assets/icon/storm-color.svg">
          <ion-label>Dispos</ion-label>
        </ion-tab-button>

        <!--/Users tabs -->

        <ion-tab-button class="text-white" @click="iconClicked('profil')" tab="profil" href="/profil">
          <img class="icon" src="../../public/assets/icon/profil.svg">
          <ion-label>Profil</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>

  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonRouterOutlet } from '@ionic/vue';
import { isPlatform } from '@ionic/vue';
import store from '@/store'

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage, IonRouterOutlet },
  data() {
    return {
      selectedTab: "",
      isClicked: "active"
    }
  },
  methods: {
    iconClicked(category) {
      this.selectedTab = category
      this.isClicked = "inactive"
    }
  },
  computed: {
    cSelectedTab() {
      return this.selectedTab
    },
    isDesktop() {
      return isPlatform('desktop')
    },
    isPharmacist() {
      return store.getters.isPharmacist;
    },
    slotPlace() {
      const isDesktop = this.isDesktop
      if (isDesktop) {
        return "top"
      } else {
        return "bottom"
      }
    },
    classSupport() {
      if (this.isDesktop) {
        return 'ionTabBarDesktop'
      }
      return ''
    }
  },
  ionViewWillEnter() {
    store.dispatch('setIsDesktop', isPlatform('desktop'))
  }
}
</script>
<style scoped>
.ionTabBarDesktop{
  padding-top: 0px !important;
  justify-content: space-around;
  border-bottom-width: 0px;
  display: flex;
}

ion-tab-bar{
  --background: var(--new-blue, var(--new-blue, rgb(255, 255, 255)));
}

.logoTopDesktop {
  width: 100px !important;
}
.icon {
  width: 27px;
  height: 27px;
  margin-bottom: 1px;
  margin-top: 3px;
}

ion-tab-button {
  --color-selected: black
}

ion-tab-button:hover {
  --background: var(--ion-tab-bar-background, var(--ion-background-color, --new-blue));
}

</style>
