import store from "@/store";
import {menuController} from "@ionic/vue";

declare global {
  interface Window {
    $crisp: any;
  }
}

export const hideCrisp = () => {
  window.$crisp.push(["do", "chat:hide"])
}

export const showCrispHelper = () => {
  menuController.close('first').then();
  window.$crisp.push(["do", "chat:show"])
  window.$crisp.push(["do", "chat:open"]);
  window.$crisp.push(["on", "chat:closed", () => {
    window.$crisp.push(["do", "chat:hide"])
  }]);
}

export const setupDataOnCrisp = () => {
  const user = store.getters.user;
  const {phone, firstName, lastName, email, id} = user;
  
  window.$crisp.push(["safe", true])
  window.$crisp.push(["config", "container:index", [1]]);
  window.$crisp.push(["set", "user:email", [email]]);
  window.$crisp.push(["set", "user:phone", [phone]]);
  window.$crisp.push(["set", "user:nickname", [`${firstName} ${lastName}`]]);
  window.$crisp.push(["set", "session:data", [[["User_ID", id]]]]);
}
