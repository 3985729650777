import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import {verifyHeaders} from '@/services/api';
import Tabs from '../views/Tabs.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/tableau-bord'
    },
    {
        path: '/connexion',
        component: () => import('@/views/sign/SignIn.vue')
    },
    {
        path: '/reinitialisation-mot-de-passe',
        component: () => import('@/views/sign/ResetPassword.vue')
    },
    {
        path: '/inscription',
        component: () => import('@/views/sign/SignUp.vue'),
        name: "signUp",
    },
    {
        path: '/confirmation-telephone',
        component: () => import('@/views/sign/ConfirmPhone.vue'),
        beforeEnter(to, from, next) {
            verifyHeaders().then((response) => {
                if (response) {
                    next();
                } else {
                    next('/connexion');
                }
            })
        },
    },
    {
        path: '/informations-profil',
        component: () => import('@/views/sign/InfoProfil.vue'),
        beforeEnter(to, from, next) {
            verifyHeaders().then((response) => {
                if (response) {
                    next();
                } else {
                    next('/connexion');
                }
            })
        },
    },
    {
        path: '/informations-pharmacie',
        component: () => import('@/views/sign/InfoPharmacy.vue'),
        beforeEnter(to, from, next) {
            verifyHeaders().then((response) => {
                if (response) {
                    next();
                } else {
                    next('/connexion');
                }
            })
        },
    },
    /////// APP ////
    {
        path: '/',
        component: Tabs,
        beforeEnter(to, from, next) {
            verifyHeaders().then((response) => {
                if (response) {
                    next();
                } else {
                    next('/connexion');
                }
            })
        },
        children: [
          // Account
            {
                path: 'tableau-bord',
                component: () => import('@/views/Dashboard.vue')
            },
            {
                path: 'profil',
                component: () => import('@/views/Profil.vue')
            },
          // User
            {
                path: 'missions',
                component: () => import('@/views/Missions.vue')
            },
            {
                path: 'mission',
                component: () => import('@/views/Mission.vue')
            },
            {
                path: 'disponibilites',
                component: () => import('@/views/Availabilities.vue')
            },
            {
                path: 'planning',
                component: () => import('@/views/Planning.vue')
            },
          // Pharmacist
            {
                path: 'creation-mission',
                component: () => import('@/views/AddMission.vue')
            },
            {
                path: 'mes-missions',
                component: () => import('@/views/PharmacistMissions.vue')
            },
            {
                path: 'ma-mission',
                component: () => import('@/views/PharmacistMission.vue')
            }
        ]
    },
    /* V2 */
    {
        path: '/v2',
        component: () => import('@/views/v2/WelcomeScreen.vue'),
    },
    {
        path: '/v2',
        component: () => import('@/views/v2/Tabs.vue'),
        beforeEnter(to, from, next) {
            // if env is prod then redirect to /
            if (process.env.NODE_ENV === 'production') {
                next('/');
            } else {
                next();
            }
        },
        children: [
            {
                path: 'inscription',
                component: () => import('@/views/v2/SignUpScreen.vue'),
            },
            {
                path: 'connexion',
                component: () => import('@/views/v2/SignInScreen.vue'),
            },
            {
                path: 'dashboard',
                component: () => import('@/views/v2/DashboardScreen.vue'),
            },
            {
                path: 'profile',
                component: () => import('@/views/v2/ProfileScreen.vue'),
            },
          
            // Shared screens between user and pharmacist on sign up
            {
                path: 'validation-telephone',
                component: () => import('@/views/v2/common-sign-up-form/OTPConfirmationScreen.vue'),
            },
            {
                path: 'completion-profil',
                component: () => import('@/views/v2/common-sign-up-form/ProfileScreen.vue'),
            },
            {
                path: 'completion-status',
                component: () => import('@/views/v2/common-sign-up-form/GradeSelectionScreen.vue'),
            },
            
            // Sign Up Pharmacist
            {
                path: 'inscription/titulaire/etape-1',
                component: () => import('@/views/v2/pharmacist-sign-up-form/Step1.vue'),
            },
            {
                path: 'inscription/titulaire/etape-2',
                component: () => import('@/views/v2/pharmacist-sign-up-form/Step2.vue'),
            },
            {
                path: 'inscription/titulaire/etape-3',
                component: () => import('@/views/v2/pharmacist-sign-up-form/Step3.vue'),
            },
          
            // Pharmacists screen
            
            {
                path: 'titulaire/mes-missions',
                component: () => import('@/views/v2/pharmacist/MissionsScreen.vue'),
            },
            {
                path: 'titulaire/mon-planning',
                component: () => import('@/views/v2/pharmacist/PlanningScreen.vue'),
            },
            {
                path: 'titulaire/mes-contrats',
                component: () => import('@/views/v2/pharmacist/ContractsScreen.vue'),
            },
            {
                path: 'titulaire/mes-interimaires',
                component: () => import('@/views/v2/pharmacist/FavoritesScreen.vue'),
            },
          
          
        ]
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router
